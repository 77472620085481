@import '../../../assets/sass/variables';

.navbar {
  $_bg: #fff;
  $_color: $main-color;
  $_color: var(--main-color);

  background: $_bg;
  color: $_color;
  display: block;
  transition: box-shadow 0.2s $animation;
  will-change: box-shadow;
  width: 100%;
  z-index: 990;

  .navbar-wrap {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 $module-rem;
    position: relative;

    > * {
      margin: 0 $module-rem;
    }
    .navbar-close {
      color: inherit;
      cursor: pointer;
      font-size: 30px;
      position: absolute;
      right: $module-rem;
      top: $module-rem * 1.5;
      width: auto !important;
      z-index: 1;
    }
    .add-patient {
      height: 100%;
      padding: ($module-rem * 2) ($module-rem * 4);
    }
    .assistant-menu {
      display: block;
      padding: 0 ($module-rem * 4) ($module-rem * 2);

      .link {
        color: inherit;
        display: block;
        font-weight: 700;
        opacity: 0.3;
        padding: ($module-rem / 2) 0;
        transition: opacity 0.2s $animation;

        .link-icon {
          margin-right: $module-rem;
          padding: 0;
          font-size: 1.3em;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .navbar-toggle {
      align-items: center;
      color: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: center;
      width: 35px; /**POSICION DEL BOTON OCULTAR**/

      span {
        background: main-palette(200);
        display: block;
        height: 3px;
        margin: 1px 0;
        width: 20px;
      }
    }
  }
  // &.boxed {
  //   .navbar-wrap {
  //     max-width: $boxed-width;
  //     max-width: var(--boxed-width);
  //   }
  // }
  &.horizontal-vertical {
    @media #{$max991} {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-$vertical-navbar-width);
      transition: transform 0.2s $animation;
      width: $vertical-navbar-width;
      will-change: transform, width;
      z-index: 997;

      .navbar-wrap {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow: auto;
        padding: 0;
        width: $vertical-navbar-width;

        > * {
          margin: 0;
          width: 100%;
        }
      }
      &.opened {
        transform: translateX(0);
      }
    }
  }
  &.vertical { /*MENU VERTICAL*/
    background: #fbfbfb;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: transform 0.2s $animation;
    width: $vertical-navbar-width;
    will-change: transform, width;
    -webkit-overflow-scrolling: touch;
    z-index: 998;

    // @media #{$max3000} {
      transform: translateX(-$vertical-navbar-width);
    // }

    .navbar-wrap {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
      width: $vertical-navbar-width;

      > * {
        margin: 0;
        width: 100%;
      }
    }
    &.opened {
      transform: translateX(0);
    }
  }
}
